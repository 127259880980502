@import 'mixins/mixins';
@import 'variables';

/* Place here styles that are override a standard behavior +
combined approaches where we use our own class to isolate some stuff within particular page */

/* Products Overview Page */
.products-overview {
  .cell-expander {
    padding-right: 0.615rem !important;
    padding-left: 1.231rem !important;
  }
  .row-expander {
    &.p-button {
      &.p-button-icon-only {
        width: 1.538rem;
        height: 1.538rem;
        padding: 0;
        display: flex;
        margin-bottom: 1.3rem;

        .cb-icon-nav-arrow-right {
          width: 6px;
          height: 10px;
        }

        .cb-icon-nav-arrow-down {
          width: 10px;
          height: 6px;
        }
      }
    }
  }
}

.products-bom-overview {
  > .p-datatable-wrapper {
    .p-datatable-thead {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }
}

/* Add Product Modal */
.p-dialog {
  .carbon-modal-header {
    height: 4.308rem;
    padding: 1.385rem 2.308rem;
    border-bottom: 1px solid rgba(224, 229, 234, 0.6);
    background: linear-gradient(0deg, rgba(245, 247, 248, 0.6), rgba(245, 247, 248, 0.6)), #ffffff;
  }
  .carbon-modal-content {
    padding: 0 1.692rem 2.308rem 2.308rem;
  }
  .carbon-modal-footer {
    height: 5.231rem;
    padding: 1.154rem 2.308rem;
    border-top: 1px solid rgba(224, 229, 234, 0.6);
  }
}

/* Add Product Info Step */
.product-info-fields {
  .p-dropdown-items {
    max-width: 100%;
  }
}

/* TODO: temporary solution, need to do it within storybook repo */
.p-steps {
  padding: 0 0 10px 0;
}

.p-message.p-message-info {
  border-radius: 8px !important;
}
