@import 'app/scss/mixins/mixins';
@import 'app/scss/variables';
@import 'app/scss/overrides-prime';
@import 'app/scss/carbon';
@import 'app/scss/carbon-icons/icons';
@import 'app/scss/color-variables';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';
/* TODO: this is temporary and need to be removed after demo */

.login-page {
  carbon-control-errors {
    position: relative;
  }
}

.p-inline-message.login-error-message.p-inline-message-error {
  margin-bottom: 20px;
  padding: 16px 16px;
  min-height: 48px;
  width: 100%;
  background: linear-gradient(0deg, rgba(240, 55, 56, 0.1), rgba(240, 55, 56, 0.1)), #ffffff;
  color: $error;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  .p-inline-message-icon {
    display: none;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  &.expanded-row-content {
    padding: 0.75rem 0rem;
  }
  &.expanded-row-content-kpi {
    padding-top: 2rem;
  }
}

.p-button:not(.p-datepicker-trigger, .row-expander) {
  height: 36px;
  padding: 8px 16px;
  font-weight: 600;
  font-style: normal;
  border-radius: 6px;
}

.p-selectbutton {
  .p-button {
    padding: 8px 12px;
  }
}

.p-inputtext,
.p-dropdown,
.p-calendar-w-btn {
  border-color: #e0e5ea;
  color: #3b4558;
}

.p-button {
  &.button-back {
    &.p-button-outlined {
      &:enabled:hover {
        color: white;
        background-color: #29aae3;
      }
    }
  }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label,
.p-float-label > input:-webkit-autofill ~ label {
  color: #3b4558;
  font-weight: 600;
}

.p-float-label > input:-webkit-autofill ~ label {
  font-size: 12px;
}

.p-tooltip {
  padding: 6px 12px;
  background-color: rgba(59, 69, 88, 0.8);
  border-radius: 6px;
}

.field {
  &.rule-operator {
    margin-bottom: 9.5px !important;
  }
  &:not(.has-clear-button, .rule-operator) {
    margin-bottom: 17.5px !important;
  }
}

.add-rule {
  margin-top: 1.5px;
}

.name-cell {
  width: fit-content;
  color: #3b4558;
  &:hover {
    color: $primaryColor;
    text-decoration: underline;
  }
}

.label-help-text {
  font-size: 11px;
  color: #90a4ae;
}

.carbon-label-control {
  label {
    color: #90a4ae;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  background-color: #ffffff !important;
}

.mt-0-2 {
  margin-top: 0.2rem !important;
}
.hover-text:hover {
  cursor: pointer;
  background-color: $backgroundBlue100;
}

.ml-2px {
  margin-left: 2px;
}

.info-text {
  color: $primaryColor !important;
}
.resize-vertical {
  resize: vertical;
}

.mr-10px {
  margin-right: 10px;
}
