$primaryColor: #29aae3;
$primaryGreen: #6cc91a;
$secondaryGreen: #79e31e;
$secondaryGreen30: #d7f7bd;
$warning: #e3881d;
$error: #ef2626;
$purple: #d51de2;
$textWhite: #ffffff;
$textBlack: #3b4558;
$textBlack80: #616b79;
$textBlack40: #b0b4bc;
$textGrey100: #90a4ae;
$textLightGrey100: #b0bbc5;
$textLightGrey80: #ced4da;
$interfaceBorder: #e0e5ea;
$interfaceBorder60: #eceff3;
$interfaceBackground: #f5f7f8;
$interfaceBackground60: #f9fafb;
$interfaceLightBlue: #e3f4ff;
$interfaceLightBlue70: #ecf9ff;
$interfaceLightBlue50: rgba(227, 244, 255, 0.5);
$interfaceLightBlue15: #fafeff;
$backgroundGrey100: #b0bbc5;
$backgroundGrey200: #e0e5ea4d;
$backgroundGrey300: #b0bbc50d;
$backgroundBlue100: #e3f4ff;

$tableOverviewCompletedBackground: rgb(121, 227, 30, 0.05);
$linkColor: #29aae3;
$lightGrey: #3b4558;

$pink: #d41ee3;
$pinkBackground: #d41ee31a;

$lightBlueBackground: #e3f4ffb2;
$lightBlueMenuItemBackground: #e3f4ff80;
$lightRedMenuItemBackground: rgba(239, 38, 38, 0.1);

$componentStatusTagPendingBackground: rgba(224, 229, 234, 0.6);
$componentStatusTagPendingText: #90a4ae;

$componentStatusTagCompletedBackground: rgba(121, 227, 30, 0.2);
$componentStatusTagCompletedText: rgba(108, 201, 26, 1);

$componentStatusTagNoDataBackground: rgba(239, 38, 38, 0.1);
$componentStatusTagNoDataText: rgba(239, 38, 38, 1);

$componentStatusTagCanceledBackground: rgba(227, 136, 29, 0.15);
$componentStatusTagCanceledText: rgba(227, 136, 29, 1);
