@import '../variables';

@mixin carbon-icon($name) {
  .cb,
  .cb-small {
    &.cb-icon-#{$name} {
      -webkit-mask: url(#{$icon-path}/icon-#{$name}.svg) no-repeat;
      background-color: $primaryColor;

      &.cb-primary {
        background-color: $primaryColor;
      }

      &.cb-topbar {
        background-color: #abdef7; // this color got with colopicker not like it is in design. Ask Julia?
      }

      &.cb-white {
        background-color: white;
      }

      &.cb-interface {
        background-color: $textGrey100;
      }

      &.cb-error {
        background-color: $error;
      }

      &.cb-warning {
        background-color: $warning;
      }

      &.cb-success {
        background-color: $primaryGreen;
      }

      &.cb-pink {
        background-color: $pink;
      }

      &.cb-grey {
        background-color: rgba(176, 187, 197, 1);
      }
    }
  }
}
