@import 'variables';

/* TODO refactor all style below */

.standard-used-group {
  .p-button:first-child {
    flex-basis: 35%;
  }
}

.carbon-modal-header {
  h2 {
    font-size: 1.231rem;
    line-height: 1.538rem;
    font-weight: 600;
  }
}

.has-clear-button {
  carbon-control-errors {
    position: relative;
  }
}

.text-size-smaller {
  font-size: 0.923rem;
}

.text-size-suffix {
  font-size: 0.846rem;
}

.table-help-text {
  font-size: 0.923rem;
  color: $textLightGrey100;
  font-weight: 400;
}

.text-success {
  color: $primaryGreen;
}
.text-info {
  color: $primaryColor;
}

.text-error {
  color: $error;
}

.text-grey-100 {
  color: $textGrey100;
}

.mt-8px {
  margin-top: 0.615rem;
}

.mt-16px {
  margin-top: 1.231rem;
}

.icon-overlay {
  display: block;
  position: relative;
  .cb {
    display: block;
  }
  .icon-dot {
    width: 0.615rem;
    height: 0.615rem;
    border-radius: 50%;
    background-color: $primaryGreen;
    position: absolute;
    top: 10%;
    left: 50%;
  }
}

.layout-topbar-action-panel {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
