/* We will start using this in incoming checkins and PR to avoid having to import variables.scss file every time in components */

:root {
  --linkColor: #29aae3;
  --disable-row-color: #b0bbc5;
  --disabled-row-background-color: #f5f7f8;
  --gray-700: #616161;
  --primaryColor: #29aae3;
  --backgroundGrey100: #b0bbc5;
  --backgroundGrey200: #e0e5ea4d;
  --textBlack: #3b4558;
}
